import React, { Component, ReactNode } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import styles from './WithNavigation.module.scss';
import { BreadCrumbLinks, OrsysContext, OrsysContextProviderValues } from 'libs/common/src/utils/OrsysContext';
import OrsysTextLogo from '../../svg/OrsysTextLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TermsOfServicePublicReadDTO } from 'libs/common/src/models/TermOfServiceModels';

import dynamic from 'next/dynamic';

const AcceptTOS = dynamic(() => import('../../AcceptTOS/AcceptTOS'), { ssr: false });

export interface WithNavigationProps {}

export interface WithNavigationState {
	termsOfService?: TermsOfServicePublicReadDTO;
}

export default class WithNavigation<P extends WithNavigationProps, S extends WithNavigationState> extends Component<
	P,
	S
> {
	static contextType = OrsysContext;

	state: any = {
		termsOfService: null,
	};

	componentDidMount() {
		this.setBreadcrumbList();
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
		this.setBreadcrumbList();
	}

	breadcrumbList?: BreadCrumbLinks[];

	setBreadcrumbList = () => {
		// @ts-ignore
		const { setBreadCrumbList, breadCrumbList }: OrsysContextProviderValues = this.context;
		try {
			if (this.breadcrumbList && JSON.stringify(breadCrumbList) !== JSON.stringify(this.breadcrumbList)) {
				setBreadCrumbList(this.breadcrumbList);
			}
		} catch (e) {}
	};

	renderWithNavs(children: ReactNode, displayFooter: boolean = true) {
		// @ts-ignore
		const { user }: OrsysContextProviderValues = this.context;

		if (user === undefined) {
			return (
				<div className={styles.loading}>
					<OrsysTextLogo />
					<FontAwesomeIcon
						icon={faSpinner}
						spin
					/>
				</div>
			);
		}

		return (
			<>
				<Header />
				<div className={styles.container}>
					<Sidebar />
					<div className={styles.content}>
						<div className={styles.mainContent}>
							{children}
							{displayFooter && <Footer />}
						</div>
					</div>
				</div>
			</>
		);
	}

	render() {
		return this.renderWithNavs([]);
	}
}
