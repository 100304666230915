import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import styles from './Header.module.scss';
import { faBars, faSignOutAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { OrsysContext, OrsysContextProviderValues } from 'libs/common/src/utils/OrsysContext';
import { useRouter, withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import { apiPost } from 'libs/common/src/utils/ApiUtils';
import Image from 'next/image';
import { injectIntl } from 'react-intl';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Select from 'react-select';
import { isAdminOrManager } from 'libs/common/src/utils/RoleUtils';
import Link from 'next/link';
import { getUserName } from 'libs/common/src/utils/NameUtils';
import { SystemRole } from 'libs/common/src/models/enum/SystemRole';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface HeaderProps extends WithRouterProps, WithIntlComponentProps {}

const Header: FC<HeaderProps> = ({ intl }) => {
	const { user, restaurantList, restaurant, setRestaurant, setMenuIsOpen, menuIsOpen, loadRestaurants, clearUser } =
		useContext<OrsysContextProviderValues>(OrsysContext);
	const queryClient = useQueryClient();
	const router = useRouter();

	const queryId = ['restaurants', 'header'];

	const restaurantsQuery = useQuery<void>({
		queryKey: queryId,
		queryFn: loadRestaurants,

		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	const toggleMenu = () => {
		setMenuIsOpen(!menuIsOpen);
	};

	const logout = async () => {
		try {
			await apiPost<void>('/auth/logout');
		} catch (e) {
			console.error('EE', e);
		}
		localStorage.clear();
		clearUser();
		router.push('/auth/login');
	};

	const options = restaurantList.map((item, index) => ({
		label: item.name?.text || `${index + 1}. étterem`,
		value: item,
	}));
	let role = SystemRole.RESTAURANT_STAFF.toLowerCase();
	if (user != null && user.role != null) {
		role = user.role.toLowerCase();
	}

	return (
		<div className={`${styles.header} ${!menuIsOpen ? styles.closed : ''}`}>
			<div className={styles.headerNavs}>
				<div className={styles.restaurantSelector}>
					<Link
						href={'/'}
						legacyBehavior>
						<Image
							src={'/icons/orsyslogo.svg'}
							alt='Orsys Logo'
							width={50}
							height={40}
						/>
					</Link>
					{restaurant || (!restaurant && isAdminOrManager(user)) ? (
						<Select
							options={options}
							className={`react-select-container ${styles.select}`}
							classNamePrefix={'react-select-head'}
							placeholder={'Nincs étterem kiválasztva'}
							onChange={(value) => {
								setRestaurant(value ? value.value : null);
								queryClient.invalidateQueries({ queryKey: ['settings'] });
								// loadRestaurants();
							}}
							isClearable={!!isAdminOrManager(user)}
							value={options.filter(({ value }) => value.id == restaurant?.id)}
						/>
					) : (
						<FontAwesomeIcon
							icon={faSpinner}
							spin
						/>
					)}
				</div>
			</div>
			<div className={styles.breadcrumbs}>
				<a
					className={styles.menuToggle}
					onClick={toggleMenu}>
					<FontAwesomeIcon icon={faBars} />
				</a>
				<Breadcrumbs />
			</div>
			<div className={styles.userInfoContainer}>
				<div className={styles.locales}>
					{/*{['hu', 'en', 'fr']*/}
					{/*	// .filter((locale) => locale != router.locale)*/}
					{/*	.map((locale) => (*/}
					{/*		<Link*/}
					{/*			key={locale}*/}
					{/*			href={router.asPath}*/}
					{/*			locale={locale}*/}
					{/*			className={styles.locale}>*/}
					{/*			{locale}*/}
					{/*		</Link>*/}
					{/*	))}*/}
					{['hu', 'en']
						.filter((locale) => locale != router.locale)
						.map((locale) => (
							<Link
								key={locale}
								href={router.asPath}
								className={styles.locale}
								locale={locale}>
								{locale}
							</Link>
						))}
				</div>
				<div className={styles.userInfo}>
					<span className={styles.name}>{`${user ? getUserName(user) : ''}`}</span>
					<span className={styles.position}>{intl.formatMessage({ id: 'enums.system.role.' + role })}</span>
				</div>
				<a
					className={styles.logoutLink}
					onClick={logout}>
					<FontAwesomeIcon icon={faSignOutAlt} />
				</a>
			</div>
		</div>
	);
};

export default withRouter<HeaderProps>(injectIntl(Header));
