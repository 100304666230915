import React, { FC, useMemo } from 'react';
import styles from './Breadcrumbs.module.scss';
import { useOrsysContext } from 'libs/common/src/utils/OrsysContext';
import Link from 'next/link';
import { injectIntl } from 'react-intl';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';

interface BreadcrumbsProps extends WithIntlComponentProps {
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props: BreadcrumbsProps) => {
  const { breadCrumbList, setBreadCrumbList } = useOrsysContext();

  const parsedBredCrumbs = useMemo(() => {
    return breadCrumbList.map((item) => {
      try {
        if (typeof item.title !== 'string') {
          item.title = props.intl.formatMessage(item.title as { id: string });
        }
      } catch (e) {

      }

      return item;
    });
  }, [breadCrumbList, props.intl]);

  return (
    <ul className={styles.breadcrumbs}>
      {parsedBredCrumbs.map(({ title, href, as, disabled, bold }, index) => {
        const key = `breadcrumbs${index}`;
        if (disabled) {
          return (
            <li
              key={key}
              className={`${bold ? styles.bold : ''}`}>
              {title as string}
            </li>
          );
        }
        return (
          <li
            key={key}
            className={`${bold ? styles.bold : ''}`}>
            <Link
              as={as}
              href={href}
              passHref>
              {title as string}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default injectIntl(Breadcrumbs);
