import React, { PureComponent } from "react";

class Footer extends PureComponent {

  render() {
    return (
        <div></div>
    );
  }
}

export default Footer;
