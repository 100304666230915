import { DeliveryOrderDetailedReadDTO } from 'libs/common/src/models/order/OrderModels';
import {
  UserAdminMinimalReadDTO,
  UserDetailedReadDTO,
  UserMinimalReadDTO,
  UserStaffReadDTO
} from 'libs/common/src/models/UserModels';
import { AddressDTO } from 'libs/common/src/models/GeneralModels';

export function getName(order?: DeliveryOrderDetailedReadDTO) {
  let result: string = '';

  if (order?.customer?.name) {
    result = order.customer.name;
  } else if (order?.deliveryAddress?.companyName) {
    result = order.deliveryAddress.companyName;
  } else {
    let name: String[] = [];

    if (order?.deliveryAddress?.lastName) {
      name.push(order.deliveryAddress.lastName);
    }
    if (order?.deliveryAddress?.firstName) {
      name.push(order.deliveryAddress.firstName);
    }
    result = name.join(' ');
  }

  return result;
}

export function getAddressName(address: AddressDTO) {
  let result: string = '';

  if (address?.companyName) {
    result = address.companyName;
  } else {
    let name: String[] = [];

    if (address?.lastName) {
      name.push(address.lastName);
    }
    if (address?.firstName) {
      name.push(address.firstName);
    }

    result = name.join(' ');
  }

  return result;
}

export function getUserName(
  user?: UserMinimalReadDTO | UserAdminMinimalReadDTO | UserDetailedReadDTO | UserStaffReadDTO
) {
  let name: String[] = [];

  if (user) {
    if (user.lastName) {
      name.push(user.lastName);
    }
    if (user.firstName) {
      name.push(user.firstName);
    }
    if (name.length === 0) {
      name.push(user.username);
    }
    return name.join(' ');
  }

  return '';
}
