import { RestaurantServiceType } from 'libs/common/src/models/enum/RestaurantServiceType';
import { Packages } from 'libs/common/src/models/enum/Subscription';
import { OrsysContextProviderValues } from 'libs/common/src/utils/OrsysContext';

export const visibleBySubscription: (
	orsysContext: OrsysContextProviderValues,
	packages?: Packages[],
	restaurantServiceTypes?: RestaurantServiceType[],
) => boolean = (orsysContext, packages, restaurantServiceTypes) => {
	const { user, restaurant, restaurantServiceType } = orsysContext;

	if (user == null) {
		return false;
	}

	let isAvailable = true;
	let tempIsAvailable = false;

	if (packages && packages.length > 0) {
		if (restaurant && restaurant.activeSubscriptions) {
			restaurant.activeSubscriptions.map((packageTextId) => {
				if (!tempIsAvailable) {
					tempIsAvailable = !!packages.find((pkg) => pkg === (packageTextId as unknown));
				}
			});

			if (!tempIsAvailable) {
				isAvailable = false;
			}
		} else {
			isAvailable = false;
		}
	}

	if (restaurantServiceTypes && restaurantServiceTypes.length > 0) {
		if (restaurantServiceType) {
			tempIsAvailable = restaurantServiceTypes.includes(restaurantServiceType);
		}

		if (!tempIsAvailable) {
			isAvailable = false;
		}
	}

	return isAvailable;
};
